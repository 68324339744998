import React from 'react';
import { UseUIConfig } from '../../../providers/UIConfigProvider/UseUIConfig';
import DialogTrigger from '../DialogTrigger/DialogTrigger';
import { deleteSheets } from '../../util/office-document';
import PropTypes from 'prop-types';

const LinksBottom = ({ isLoading, restartWorkflow }) => {
  const config = UseUIConfig();
  const UIConfig = config && config.UIConfig;

  const disabledProps = {
    disabled: isLoading
  };

  const handleForecastReset = async arg => {
    if (arg.message === 'yes') {
      console.log('User clicked Yes');
      deleteSheets();
      restartWorkflow();
    } else {
      console.log('User clicked No');
    }
  };

  const handleSheetsDelete = async arg => {
    if (arg.message === 'yes') {
      console.log('User clicked Yes');
      deleteSheets();
    } else {
      console.log('User clicked No');
    }
  };

  return (
    <>
      {UIConfig && (
        <div className='eiu-fdp-info bottom-links'>
          <div>
            <DialogTrigger
              processMessage={handleSheetsDelete}
              actionText='Delete all sheets'
              url={`${window.location.origin}/excel/confirmationDialog?type=deleteSheets`}
              isDisabled={disabledProps.disabled}
              isNavigationLink
            />
          </div>
          <div>
            <DialogTrigger
              processMessage={handleForecastReset}
              actionText='Reset forecast'
              url={`${window.location.origin}/excel/confirmationDialog?type=resetForecast`}
              isDisabled={disabledProps.disabled}
              isNavigationLink
            />
          </div>
        </div>
      )}
    </>
  );
};

LinksBottom.propTypes = {
  isLoading: PropTypes.bool,
  restartWorkflow: PropTypes.func
};

export default LinksBottom;
