import { useContext } from 'react';
import { UIConfigContext } from './UIConfigProvider';

export const UseUIConfig = () => {
  const context = useContext(UIConfigContext);
  if (!context) {
    throw new Error('useUIConfig must be used within useUIConfigProvider');
  }
  return context;
};
