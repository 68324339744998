import csv from 'csvtojson';
import { JudgementType } from './constants';

export const getFrequencyAdjustedSeriesName = (uiConfigSheet, seriesName) =>
  uiConfigSheet?.frequencies?.annual && !uiConfigSheet?.frequencies?.quarterly ? `yy_${seriesName}` : seriesName;

export const isResidualJudgementSeries = (seriesName, manifest, uiConfigSheet) => {
  const adjustedSeriesName = getFrequencyAdjustedSeriesName(uiConfigSheet, seriesName);
  return getJudgementType(adjustedSeriesName, manifest) === JudgementType.RESIDUAL;
};

export const getJudgementType = (seriesName, manifest) => {
  if (!manifest?.input_series.includes(seriesName)) {
    return JudgementType.NOT_JUDGEMENT;
  }
  return manifest?.exogenize_endogenize[seriesName] ? JudgementType.TUNE : JudgementType.RESIDUAL;
};

export const processCsvData = async csvString => {
  const data = [];
  await csv()
    .preFileLine((fileLine, index) => (index === 0 ? fileLine.toLowerCase() : fileLine))
    .fromString(csvString)
    .then(entries => {
      entries.forEach(entry => {
        data.push(entry);
      });
    });
  return data;
};

export const mutateResidualJudgementNames = data => {
  data.ui.sheets.forEach(sheet => {
    sheet.sections.forEach(section => {
      const manifest = data.models.find(model => model.model === section.model[0])?.manifest.manifest;

      section.series
        .filter(series => series.judgement && series.name && isResidualJudgementSeries(series.name, manifest, sheet))
        .forEach(series => (series.name = `${series.name}_res`));
    });
  });
};

export const getSheetFrequencies = sheetFrequencies =>
  Object.entries(sheetFrequencies).map(([frequencyKey]) => frequencyKey);
