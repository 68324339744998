import React from 'react';

const Protected = () => {
  return (
    <div id='protected'>
      <div>
        <p>This is a protected component</p>
      </div>
    </div>
  );
};

export default Protected;
