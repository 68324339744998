import { logWithGlobalAttributes } from '../../providers/LoggingContextProvider/LoggingContextProvider';
import { calcuateFrequencyRange } from './FrequencyCalculation';
import { deepCopyObject } from './DataModifier';

export const generateDataRequestsForSheets = (config, setUIConfig, dataBranch) => {
  console.log('New Code generateDataRequestsForSheets');
  const searchRequestBranch = dataBranch || config.branch;
  const requestMapping = {};
  config.models.forEach(currentModel => {
    const mapping = deepCopyObject(currentModel.mapping.mapping);
    requestMapping[currentModel.model] = mapping;
  });

  const sheetDataRequests = [];
  const includeMetadata = true;

  config.ui.sheets.forEach(sheet => {
    const annualFrequencies = sheet.frequencies.annual;
    const quarterlyFrequencies = sheet.frequencies.quarterly;

    let requests = [];
    let annualSeriesIds = [];
    let quarterlySeriesIds = [];
    const version =
      sheet.type === 'forecast_checker' ? [searchRequestBranch, config.meta_data.snapshot_id] : [searchRequestBranch];

    if (annualFrequencies) {
      const calculatedAnnualDateRange = calcuateFrequencyRange(annualFrequencies, 'annual');
      requests.push({
        frequency: 'annual',
        toDate: calculatedAnnualDateRange.toDate,
        fromDate: calculatedAnnualDateRange.fromDate,
        seriesUrns: annualSeriesIds
      });
    }

    if (quarterlyFrequencies) {
      const calcuatedQuarterlyDateRange = calcuateFrequencyRange(quarterlyFrequencies, 'quarterly');
      requests.push({
        frequency: 'quarterly',
        toDate: calcuatedQuarterlyDateRange.toDate,
        fromDate: calcuatedQuarterlyDateRange.fromDate,
        seriesUrns: quarterlySeriesIds
      });
    }
    sheet.sections.forEach(section => {
      section.series.forEach(series => {
        if (series.seriesId) {
          if (series.seriesId.annual && series.seriesId.annual !== 'NO_URN_FOUND') {
            if (annualFrequencies) {
              annualSeriesIds.push(series.seriesId.annual);
            } else {
              logWithGlobalAttributes(
                'warn',
                {},
                'found quarterly seriesId when no quarterly frequency was present in UISheetconfig',
                {
                  name: sheet.displayName,
                  id: series.seriesId.annual
                }
              );
            }
          }
          if (series.seriesId.quarterly && series.seriesId.quarterly !== 'NO_URN_FOUND') {
            if (quarterlyFrequencies) {
              quarterlySeriesIds.push(series.seriesId.quarterly);
            } else {
              logWithGlobalAttributes(
                'warn',
                {},
                'found quarterly seriesId when no quarterly frequency was present in UISheetconfig',
                {
                  name: sheet.displayName,
                  id: series.seriesId.quarterly
                }
              );
            }
          }
        } else {
          // the below check allows us to see if model requested from UI config is available in model mappings
          if (section.model.length > 0 && requestMapping[section.model[0]]) {
            const quarterlyMappingSeries = requestMapping[section.model[0]].find(x => x.name === series.name);
            const annualMappingSeries = requestMapping[section.model[0]].find(x => x.name === `yy_${series.name}`);
            if (quarterlyMappingSeries && quarterlyMappingSeries.urn) {
              series.seriesId = {
                quarterly: quarterlyMappingSeries.urn
              };
              quarterlySeriesIds.push(quarterlyMappingSeries.urn);
            }
            if (annualMappingSeries && annualMappingSeries.urn) {
              series.seriesId = {
                annual: annualMappingSeries.urn
              };
              annualSeriesIds.push(annualMappingSeries.urn);
            }
          }
        }
      });
    });

    sheetDataRequests.push({
      version,
      includeMetadata,
      searchData: requests.map(requestItem => {
        return {
          ...requestItem,
          seriesUrns: [...new Set(requestItem.seriesUrns)]
        };
      }),
      sheetName: sheet.displayName
    });
  });

  setUIConfig(config);

  return sheetDataRequests;
};
