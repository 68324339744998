import React from 'react';
import { dateFormatterDDMMYYYY } from '../../util/date_utils';
import PropTypes from 'prop-types';
import ForecastModeSelector from './ForecastModeSelector';

const ForecastStatus = props => {
  const { forecastPublishState, forecastNotAvailable } = props;
  return (
    <>
      <div className='eiu-fdp-published-state-for-forecast'>
        {forecastNotAvailable && <p>No forecast has been published for this geography.</p>}
        {!forecastNotAvailable && (
          <>
            <p className='forecast-state'>
              <span className={forecastPublishState?.status === 'draft' ? 'current-state' : null}>Draft</span> -{' '}
              <span className={forecastPublishState?.status === 'review' ? 'current-state' : null}>Review</span> -{' '}
              <span className={forecastPublishState?.status === 'published' ? 'current-state' : null}>Published</span>
            </p>
            <p>
              <span>Started by: </span>{' '}
              {forecastPublishState?.startedBy && forecastPublishState?.startedAt && (
                <span>
                  {forecastPublishState.startedBy} - {dateFormatterDDMMYYYY(forecastPublishState.startedAt)}
                </span>
              )}
            </p>
            <p>
              <span>Submitted by: </span>{' '}
              {forecastPublishState?.submittedBy && forecastPublishState?.submittedAt && (
                <span>
                  {forecastPublishState.submittedBy} - {dateFormatterDDMMYYYY(forecastPublishState.submittedAt)}
                </span>
              )}
            </p>
            <p>
              <span>Approved by: </span>{' '}
              {forecastPublishState?.approvedBy && forecastPublishState?.approvedAt && (
                <span>
                  {forecastPublishState.approvedBy} - {dateFormatterDDMMYYYY(forecastPublishState.approvedAt)}
                </span>
              )}
            </p>
          </>
        )}
        <ForecastModeSelector />
      </div>
    </>
  );
};

ForecastStatus.propTypes = {
  forecastPublishState: PropTypes.object,
  forecastNotAvailable: PropTypes.bool
};

export default ForecastStatus;
