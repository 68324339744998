import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import Header from '../Header/Header';
import { UseUserInfo } from '../../../providers/UserInfoProvider/UseUserInfo';

const UserDetails = () => {
  const { userDetails, setUserDetails } = UseUserInfo();
  const { oktaAuth } = useOktaAuth();

  const logout = () => {
    oktaAuth.tokenManager.clear();
    setUserDetails(null);
  };

  const userDisplayName = userDetails?.name?.startsWith('t_') ? userDetails.name.substring(2) : userDetails?.name;

  return (
    <>
      <Header userDisplayName={userDisplayName} logout={logout} />
    </>
  );
};

export default UserDetails;
