import React, { useEffect, useState } from 'react';
import axios from 'axios';
import pako from 'pako';
import { useOktaAuth } from '@okta/okta-react';
import { useNavigate } from 'react-router-dom';
import { UseActionState } from '../../../providers/ActionStateProvider/UseActionState';
import { logWithGlobalAttributes } from '../../../providers/LoggingContextProvider/LoggingContextProvider';

export const compressQueryParam = value => {
  const compressed = pako.gzip(value);
  const charCodeCompressed = btoa(String.fromCharCode(...new Uint8Array(compressed)));
  return encodeURIComponent(charCodeCompressed);
};

const APIAuthInterceptor = () => {
  const { authState } = useOktaAuth();
  const navigate = useNavigate();
  const { applicationActionState } = UseActionState();
  const [branches, setBranches] = useState({
    git: null,
    dw: null
  });

  useEffect(() => {
    if (applicationActionState?.gitBranch && applicationActionState?.dwBranch) {
      setBranches({
        git: applicationActionState.gitBranch,
        dw: applicationActionState.dwBranch
      });
    }
  }, [applicationActionState?.gitBranch, applicationActionState?.dwBranch]);

  useEffect(() => {
    const interceptor = axios.interceptors.request.use(async config => {
      const token = authState?.accessToken?.accessToken;
      if (token) {
        if (config.method === 'post') {
          config.data = {
            ...config.data,
            token: `Bearer ${token}`
          };
        } else if (config.method === 'get') {
          const compressedToken = compressQueryParam(`Bearer ${token}`);
          config.url = `${config.url}?token=${compressedToken}`;
        } else {
          config.headers = config.headers || {};
          config.headers['Authorization'] = `Bearer ${token}`;
        }
      }
      if (config.method === 'get' || config.method === 'delete') {
        config.params = {
          ...(config.params || {}),
          gitBranch: branches.git || '',
          dwBranch: branches.dw || ''
        };
      } else if (config.method === 'post' || config.method === 'put') {
        config.data = {
          ...(config.data || {}),
          gitBranch: branches.git || '',
          dwBranch: branches.dw || ''
        };
      }
      logWithGlobalAttributes(
        'info',
        {
          userInfo: authState?.idToken?.claims?.name
        },
        `API Request got ${config?.url?.split('?')[0]}`,
        {
          requestData: {
            ...config?.data,
            token: config?.data?.token ? 'Bearer [REDACTED]' : null
          },
          requestParams: {
            ...config?.params,
            token: config?.params?.token ? 'Bearer [REDACTED]' : null
          }
        }
      );
      return config;
    });

    return () => {
      axios.interceptors.request.eject(interceptor);
    };
  }, [authState, branches.git, branches.dw]);

  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response.status === 401) {
        navigate('/excel/index');
        return;
      } else {
        return error;
      }
    }
  );

  return <></>;
};

export default APIAuthInterceptor;
