import React from 'react';
import { Indicator } from '@economist/design-system/dist/umd/common';
import PropTypes from 'prop-types';
import './SplashScreen.css';

const SplashScreen = props => {
  const { header, text } = props;
  return (
    <div className='excel_splash-container'>
      <h1>{header}</h1>
      <div className='excel_splash-indicator-container'>
        <Indicator />
      </div>
      <p>{text}</p>
    </div>
  );
};

SplashScreen.propTypes = {
  header: PropTypes.string,
  text: PropTypes.string
};
export default SplashScreen;
