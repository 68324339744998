/* global Excel */

import globalVars from '../../styles/dsvars';
import { getSheetFrequencies } from './uiConfigHelper';
import { FixRowColumnDesign, columnStyleForecastChecker } from './worksheet-design-config';

let sheetEventListeners = {};
//Only used for testing
export const getSheetEventListeners = () => sheetEventListeners;
export const clearSheetEventListeners = () => (sheetEventListeners = {});

let sheetStyleEventListeners = {};
export const getSheetStyleEventListeners = () => sheetStyleEventListeners;
export const clearSheetStyleEventListeners = () => (sheetStyleEventListeners = {});

let sheetRenders = [];
//Only use the reset function for tests
export const resetSheetRenderState = () => (sheetRenders = []);

export const getSheetRenders = () => sheetRenders;

export const updateSheetRenders = updatedSheetRenders => {
  if (sheetRenders) sheetRenders = updatedSheetRenders;
};

export const clearSheetRender = sheetName => {
  const sheet = sheetRenders.find(x => x.sheetName === sheetName);
  if (sheet) {
    sheet.nextRenderAt = undefined;
    sheet.operations = [];
  }
};
export const initializeSheetRender = sheetName => {
  const sheetRender = sheetRenders.find(x => x.sheetName === sheetName);
  if (!sheetRender) {
    sheetRenders.push({
      sheetName: sheetName,
      operations: [],
      nextRenderAt: undefined
    });
  }
};
export const addSheetRenderOperation = (sheetName, operation) => {
  const sheetRender = sheetRenders.find(sheet => sheet.sheetName === sheetName);
  if (sheetRender && !sheetRender.operations.includes(operation)) {
    const renderOperationBuffer = operation === 'values' ? 500 : 0;

    sheetRender.operations.push(operation);
    sheetRender.nextRenderAt = new Date().getTime() + renderOperationBuffer;
    // console.log(`Sheet render operation "${operation}" added`, sheetRender);
  }
};

//We only need one method which does this. The other methods are then easier to test.
export async function wrapWithExcel(someFunction, ...args) {
  try {
    return await Excel.run({ delayForCellEdit: true }, async context => someFunction(context, ...args));
  } catch (error) {
    console.error(error);
  }
}

export const deleteSheetsPrivate = async (context, sheetName) => {
  let sheetRendersNames = sheetRenders.map(sheet => sheet.sheetName);
  const sheets = context.workbook.worksheets;
  const updatedSheetName = `${sheetName} - ${Math.floor(Math.random() * 1000)}`;
  sheets.load('items/name');
  await context.sync();
  //adding of the placeholder sheet needs to be done first because we cannot get to a point of no sheets
  if (sheetRendersNames.length === 0) {
    sheets.add(updatedSheetName);
  }
  sheets.items.forEach(function(sheet) {
    // this check will allow the extra sheet to be always retained
    if (sheetRendersNames.length === 0) {
      sheet.delete();
    } else if (sheetRendersNames.length > 0 && sheetRendersNames.includes(sheet.name)) {
      sheet.delete();
    }
  });
  await context.sync();
};

const setActiveSheetPrivate = async (context, sheetName) => {
  let sheet = context.workbook.worksheets.getItem(sheetName);
  sheet.activate();
  await context.sync();
};

export const clearSheetPrivate = async (context, sheetName) => {
  try {
    const sheet = context.workbook.worksheets.getItem(sheetName);
    if (sheet) {
      const usedRange = sheet.getUsedRange();
      usedRange.clear();
      await context.sync();
    }
  } catch (err) {
    return { errorType: err.code };
  }
};

export const doesSheetExistPrivate = async (context, sheetName) => {
  const sheetNamesArr = [];
  const sheets = context.workbook.worksheets;
  sheets.load('items/name');
  await context.sync();
  sheets.items.forEach(function(sheet) {
    sheetNamesArr.push(sheet.name);
  });
  return sheetNamesArr.includes(sheetName);
};

export const createSheet = async sheetName => await wrapWithExcel(createSheetPrivate, sheetName);
export const createSheetPrivate = async (context, sheetName) => {
  const sheets = context.workbook.worksheets;
  const isSheetPresent = await doesSheetExistPrivate(context, sheetName);
  if (!isSheetPresent) {
    sheets.add(sheetName);
    await context.sync();
    if (sheetEventListeners[sheetName]) {
      sheetEventListeners[sheetName] = undefined;
    }
    return true;
  }
  return false;
};

export const clearSheet = async sheetName => await wrapWithExcel(clearSheetPrivate, sheetName);

export const deleteSheets = async () => await wrapWithExcel(deleteSheetsPrivate, `Scratch area`);

export const setActiveSheet = async sheetName => await wrapWithExcel(setActiveSheetPrivate, sheetName);

export const insertBulkText = async (values, sheetName, startRow = 0, startColumn = 0) => {
  const startTime = new Date().getTime();
  await wrapWithExcel(insertBulkTextPrivate, values, sheetName, startRow, startColumn);
  const endTime = new Date().getTime();
  console.log(`Time taken: The function insertBulkText for ${sheetName} took ${endTime - startTime} to complete`);
};

export const insertBulkTextPrivate = async (context, values, sheetName, startRow, startColumn) => {
  try {
    context.workbook.application.suspendApiCalculationUntilNextSync();
    let sheet = context.workbook.worksheets.getItem(sheetName);
    sheet.load('name');
    const range = sheet.getRangeByIndexes(startRow, startColumn, values.length, values[0].length);
    range.values = values;
    await context.sync();
  } catch (err) {
    return { errorType: err.code };
  }
};

export const updateSheetIndividualValues = async (sheetName, cellUpdates) => {
  await wrapWithExcel(updateSheetIndividualValuesPrivate, sheetName, cellUpdates);
};

export const reApplyJudgementFormatting = async (sheetName, cellUpdates) => {
  await wrapWithExcel(reApplyJudgementFormattingPrivate, sheetName, cellUpdates);
};

export const reApplyJudgementFormattingPrivate = async (context, sheetName, cellUpdates) => {
  let worksheet = context.workbook.worksheets.getItem(sheetName);
  worksheet.load('name');
  await context.sync();
  cellUpdates.forEach(cellUpdate => {
    const cellAddress = `'${cellUpdate.sheetName}'!${cellUpdate.address}`;
    const range = worksheet.getRange(cellAddress);
    if (cellUpdate.endogenize) applyJudgementTuneStyling(range);
    else applyJudgementStyling(range);
  });
};

export const updateSheetIndividualValuesPrivate = async (context, sheetName, cellUpdates) => {
  try {
    let worksheet = context.workbook.worksheets.getItem(sheetName);
    worksheet.load('name');
    await context.sync();

    cellUpdates.forEach(cellUpdate => {
      const range = worksheet.getRange(cellUpdate.address);
      range.format.fill.color = globalVars['--ds-color-excel-calculation-mode'];
      range.values = [[cellUpdate.value]];
    });
  } catch (err) {
    return { errorType: err.code };
  }
};

export const updateSeriesValueForSheet = async (updatedValues, sheetName) =>
  await wrapWithExcel(updateSeriesValueForSheetPrivate, updatedValues, sheetName);

const updateSeriesValueForSheetPrivate = async (context, updatedValues, sheetName) => {
  const isSheetPresent = await doesSheetExistPrivate(context, sheetName);
  if (!isSheetPresent) {
    return;
  }
  let sheet = context.workbook.worksheets.getItem(sheetName);
  let searchRange = sheet.getUsedRange();
  searchRange.load('columnCount');
  await context.sync();
  for (const data of updatedValues) {
    updateRowValuePrivate(sheet, data.row, data.rowValues, searchRange.columnCount);
  }
  await context.sync();
};

const updateRowValuePrivate = async (sheet, rowNumber, values, columnCount) => {
  const rowRange = sheet.getRangeByIndexes(rowNumber - 1, 1, 1, columnCount - 1);
  rowRange.values = [values];
};

export const applyColumnGroupings = async (sheetName, groups) =>
  await wrapWithExcel(applyColumnGroupingsPrivate, sheetName, groups);

export const applyColumnGroupingsPrivate = async (context, sheetName, groups) => {
  if (!groups) {
    return;
  }

  try {
    const sheet = context.workbook.worksheets.getItem(sheetName);
    const usedRange = sheet.getUsedRange();
    const firstRow = usedRange.getRow(0);

    const getCellAddress = str => {
      return str.split('!')[1];
    };

    for (let i = 0; i < groups.length; i++) {
      const element = groups[i];
      const grpStart = firstRow.find(element.start, {
        searchDirection: Excel.SearchDirection.forward
      });
      grpStart.load('address');
      // eslint-disable-next-line office-addins/no-context-sync-in-loop
      await context.sync(); //TODO currently no grouping so no impact we can reviste when Implementing Grouping
      const startCell = getCellAddress(grpStart.address);
      const grpEnd = firstRow.find(element.end, {
        searchDirection: Excel.SearchDirection.forward
      });
      grpEnd.load('address');
      // eslint-disable-next-line office-addins/no-context-sync-in-loop
      await context.sync();
      const endCell = getCellAddress(grpEnd.address);
      await createColoumGroupingPrivate(context, sheetName, `${startCell.charAt(0)}:${endCell.charAt(0)}`);
    }
  } catch (e) {
    console.log('Failed to apply groupings');
  }
};

export const removeOnWorksheetChangedEvent = async sheetName => {
  if (sheetEventListeners[sheetName]) {
    // console.log('Removing onChange listener for', sheetName, sheetEventListeners[sheetName], sheetEventListeners);
    await Excel.run(sheetEventListeners[sheetName].context, async context => {
      await doWorksheetOnChangeRemoval(context, sheetEventListeners, sheetName);
    });
  }

  return true;
};

export const removeOnFormatChangedEvent = async (sheetName, isNewSheet = false) => {
  if (sheetStyleEventListeners[sheetName]) {
    // console.log(
    //   'Removing style listener for',
    //   sheetName,
    //   sheetStyleEventListeners[sheetName],
    //   sheetStyleEventListeners
    // );
    if (isNewSheet) {
      sheetStyleEventListeners[sheetName] = undefined;
      return true;
    }
    await Excel.run(sheetStyleEventListeners[sheetName].context, async context => {
      await doWorksheetOnChangeRemoval(context, sheetStyleEventListeners, sheetName);
    });
  }
  return true;
};

export const doWorksheetOnChangeRemoval = async (context, listeners, sheetName) => {
  listeners[sheetName].remove();
  listeners[sheetName] = undefined;
};

export const onWorksheetChanged = async (e, residualInfo, sheetName, addResidualUpdate, setApplicationActionState) =>
  await wrapWithExcel(
    onWorksheetChangedPrivate,
    e,
    residualInfo,
    sheetName,
    addResidualUpdate,
    setApplicationActionState
  );

export const onWorksheetChangedPrivate = async (
  context,
  e,
  residualInfo,
  sheetName,
  addResidualUpdate,
  setApplicationActionState,
  currentMode
) => {
  context.workbook.application.suspendApiCalculationUntilNextSync();
  if (e?.triggerSource === 'ThisLocalAddin') {
    return false;
  }
  if (!e?.details) {
    console.error('Multi cell edit detected - reverting sheet', sheetRenders);
    addSheetRenderOperation(sheetName, 'values');
    return;
  }

  const details = e.details;
  const address = e.address;
  const cellAddress = `${sheetName}!${address}`;
  const escapedCellAddress = `'${sheetName}'!${address}`;

  // console.log(`comparing ${cellAddress} to judgement info`, residualInfo, e);

  if ((typeof details.valueAfter !== 'number' && details.valueAfter !== '') || currentMode === 'read') {
    console.error(`updated value is not a number reverting value to ${details.valueBefore}`);
    const sheet = context.workbook.worksheets.getActiveWorksheet();
    sheet.getRange(address).values = [[details.valueBefore]];
    await context.sync();
    return;
  }

  if (details.valueAfter === details.valueBefore) {
    // console.log('no change in value detected');
    return;
  }

  context.workbook.application.suspendApiCalculationUntilNextSync();
  const sheetFrequencies = getSheetFrequencies(residualInfo[0]?.frequencies);
  let judgementUpdated = false;

  residualInfo.forEach(judgementRow => {
    sheetFrequencies.forEach(frequency => {
      const frequencyData = judgementRow.frequencies[frequency];
      const judgementCellIndex = frequencyData.cell.findIndex(x => x === cellAddress || x === escapedCellAddress);

      if (judgementCellIndex > -1) {
        const judgementUpdate = {
          name: judgementRow.name,
          model: judgementRow.model,
          endogenize: judgementRow.endogenize,
          transform: frequencyData.transform,
          period: frequencyData.date[judgementCellIndex],
          value: details.valueAfter,
          address,
          sheetName: sheetName
        };
        console.log('adding residual update judgementUpdate', judgementUpdate);
        addResidualUpdate(judgementUpdate);
        setApplicationActionState(o => {
          return {
            ...o,
            getForecastConfig: {
              isOperationAllowed: false
            },
            updateResiduals: {
              isOperationAllowed: true
            },
            forecastReadyForReview: {
              isOperationAllowed: false
            },
            forecastApproved: {
              isOperationAllowed: false
            },
            taskPaneActions: {
              isOperationAllowed: false
            }
          };
        });
        judgementUpdated = true;
      }
    });
  });

  const sheet = context.workbook.worksheets.getActiveWorksheet();
  if (!judgementUpdated) {
    console.log(`non judgement change detected - reverting value to ${details.valueBefore}`);
    sheet.getRange(address).values = [[details.valueBefore]];
    await context.sync();
  } else {
    const range = sheet.getRange(address);
    range.format.fill.color = globalVars['--ds-color-excel-calculation-mode'];
    await context.sync();
  }
};

export const insertStyle = async (
  formatedData,
  sheet,
  groups,
  addResidualUpdate,
  setApplicationActionState,
  residualValues,
  approvalTime,
  currentMode
) => {
  const startTime = new Date().getTime();

  await wrapWithExcel(
    insertStylePrivate,
    formatedData,
    sheet,
    groups,
    addResidualUpdate,
    setApplicationActionState,
    residualValues,
    approvalTime,
    currentMode
  );
  const endTime = new Date().getTime();
  console.log(
    `Time taken: The function insertStyle for ${formatedData.sheetName} took ${endTime - startTime} to complete`
  );
};

export const insertStylePrivate = async (
  context,
  formatedData,
  sheet,
  groups,
  addResidualUpdate,
  setApplicationActionState,
  residualValues,
  approvalTime,
  currentMode
) => {
  context.workbook.application.suspendApiCalculationUntilNextSync();
  await removeOnWorksheetChangedEvent(formatedData.sheetName);

  const values = formatedData.styleData;
  const fixRowCount = sheet.fixRowCount;
  const fixColumnCount = sheet.fixColumnCount;
  const seriesNameStyle = formatedData.seriesStyle;
  const name = formatedData.sheetName;

  let residualValuesArr = [];
  residualValues.forEach(val => {
    residualValuesArr = residualValuesArr.concat(val);
  });

  const worksheet = context.workbook.worksheets.getItem(name);

  //add the Listener first to minimize the time since the Listener was removed (also prevents editing non-judgments whilst styling is still being applied)
  sheetEventListeners[name] = worksheet.onChanged.add(e => {
    onWorksheetChangedPrivate(
      context,
      e,
      residualValuesArr,
      name,
      addResidualUpdate,
      setApplicationActionState,
      currentMode
    );
  });

  initializeSheetRender(name);

  const usedRange = worksheet.getUsedRange();

  usedRange.ungroup(Excel.GroupOption.byColumns);
  usedRange.ungroup(Excel.GroupOption.byRows);
  // The following code here allows to remove any existing protection at sheet level
  // sheet.protection.unprotect('');
  // sheet.load("protection/protected");
  worksheet.load('name');
  usedRange.getColumn(0).format.autofitColumns();

  /*
      this will not change the value but update formatting on excel and applying on all cells
      when residuals are added to "n" decimals the models will get exact values
      but formatting will enable 2 decimals only
    */
  usedRange.numberFormat = '0.00';
  usedRange.getRow(0).numberFormat = '0';
  if (name === 'Forecast checker') {
    usedRange.getRow(1).numberFormat = '0';
  }

  await context.sync();
  const sheetName = worksheet.name;

  await applyColumnGroupingsPrivate(context, sheetName, groups);

  const judgementRows = [];
  const frequencyBreakIndexes = values[0]
    ? values[0]
        .map((x, index) => (x === null ? index : -1))
        .filter(x => x > -1)
        .concat([values[0].length + 1])
    : [];

  const sheetFrequencies = getSheetFrequencies(sheet.frequencies);

  let currentCellSyncCount = 0;

  const stylingFunctions = {
    tune: applyJudgementTuneStyling,
    residual: applyJudgementStyling
  };
  context.workbook.application.suspendApiCalculationUntilNextSync();
  for (let i = 0; i < values.length; i++) {
    const currentJudgementCells = {};
    sheetFrequencies.forEach(frequency => {
      currentJudgementCells[frequency] = [];
    });

    const { indentLevel, underline, isBold, type, isItalic, backgroundColor } = seriesNameStyle[i];
    let isJudgementRow = false;
    let val = values[i];
    const cellFirst = usedRange.getCell(i, 0);
    cellFirst.format.indentLevel = indentLevel;
    cellFirst.format.font.underline = underline;
    cellFirst.format.font.italic = isItalic || false;

    if (stylingFunctions[type]) {
      stylingFunctions[type](cellFirst);
    }

    for (let j = 0; j < val.length; j++) {
      const frequencyType =
        sheetFrequencies[frequencyBreakIndexes.findIndex(columnBreakIndex => j <= columnBreakIndex)];
      const cell = usedRange.getCell(i, j);
      if (isBold) {
        cell.format.font.bold = isBold;
      }

      if (val[j] === 'r') {
        cell.format.font.color = globalVars['--ds-color-economist-red-60'];
        cell.format.font.bold = true;
      }

      if (val[j] === 'b') {
        cell.format.font.color = globalVars['--ds-color-chicago-55'];
        cell.format.font.bold = true;
      }

      if (val[j] === 'f') {
        cell.format.fill.color = globalVars['--ds-color-hong-kong-90'];
      }

      if (backgroundColor) {
        cell.format.fill.color = backgroundColor;
        cell.format.horizontalAlignment = Excel.HorizontalAlignment.right;
        cellFirst.format.horizontalAlignment = Excel.HorizontalAlignment.left;
      }

      if (val[j] === 'j' || val[j] === 't') {
        const stylingFunction = val[j] === 'j' ? applyJudgementStyling : applyJudgementTuneStyling;

        stylingFunction(cell);
        cell.load('address');
        currentJudgementCells[frequencyType].push(cell);
        isJudgementRow = true;
      }
    }
    if (isJudgementRow) {
      const seriesIds = val[0]?.split(',');
      sheetFrequencies.forEach((frequency, index) => {
        judgementRows.push({
          frequency,
          seriesId: seriesIds[index],
          cells: currentJudgementCells[frequency]
        });
      });
    }

    // Ensure we don't have one huge context.sync which breakes the 5MB payload size
    // TODO 3000 cells brings the request size to around ~3.7MB. larger mocking not priority EIUFDP-2252
    currentCellSyncCount += val.length;
    if (currentCellSyncCount >= 3000) {
      await context.sync();
      currentCellSyncCount = 0;
    }
  }

  await context.sync();

  judgementRows.forEach(judgementRow => {
    sheetFrequencies.forEach(frequency => {
      const matchedIndex = residualValuesArr.findIndex(x => x.frequencies[frequency].id === judgementRow?.seriesId);
      if (matchedIndex > -1) {
        residualValuesArr[matchedIndex].frequencies[frequency].cell = judgementRow.cells.map(
          cell => cell?.address ?? null
        );
      }
    });
  });

  FixRowColumnDesign(worksheet, fixRowCount, fixColumnCount);
  if (sheetName === 'Forecast checker') {
    const { branchDataPeriodLength } = seriesNameStyle[0];
    forecasteHeaderStyle(context, worksheet, branchDataPeriodLength, approvalTime);
  }
  await addOnFormatChangedToWorksheetPrivate(context, worksheet, sheetName);
  await context.sync();
};

export const addOnFormatChanged = async sheetName => await wrapWithExcel(addOnFormatChangedPrivate, sheetName);

export const addOnFormatChangedPrivate = async (context, sheetName) => {
  const worksheet = context.workbook.worksheets.getItem(sheetName);
  await addOnFormatChangedToWorksheetPrivate(context, worksheet, sheetName);
};

export const addOnFormatChangedToWorksheetPrivate = async (context, worksheet, sheetName) => {
  if (!sheetStyleEventListeners[sheetName]) {
    // console.log(`addOnFormatChangedToWorksheetPrivate adding styling event ${sheetName}`, sheetStyleEventListeners);
    sheetStyleEventListeners[sheetName] = worksheet.onFormatChanged.add(e => {
      onFormatChangedPrivate(e, sheetName);
    });
    await context.sync();
  }
};

export const onFormatChangedPrivate = async (e, sheetName) => addSheetRenderOperation(sheetName, 'style');

const applyJudgementStyling = cell => {
  cell.format.font.color = globalVars['--ds-color-london-100'];
  cell.format.fill.color = globalVars['--ds-color-london-35'];
};

const applyJudgementTuneStyling = cell => {
  cell.format.font.color = globalVars['--ds-color-london-100'];
  cell.format.fill.color = globalVars['--ds-color-chicago-45'];
};

export const generateCellAddress = (branchDataPeriodLength = 0) => {
  const result = {};
  const codeA = 65;
  result.currentBranchStart = `${String.fromCharCode(codeA + 2)}1`;
  result.currentBranchEnd = `${String.fromCharCode(codeA + 1 + branchDataPeriodLength)}1`;
  result.previousBranchStart = `${String.fromCharCode(codeA + 3 + branchDataPeriodLength)}1`;
  result.previousBranchEnd = `${String.fromCharCode(codeA + 2 + 2 * branchDataPeriodLength)}1`;
  result.diffStart = `${String.fromCharCode(codeA + 4 + 2 * branchDataPeriodLength)}1`;
  result.diffEnd = `${String.fromCharCode(codeA + 3 + 3 * branchDataPeriodLength)}1`;
  return result;
};

export const forecastDateFormator = val => {
  const date = new Date(val);
  const yyyy = date.toLocaleString('default', { year: 'numeric' });
  const mm = date.toLocaleString('default', { month: 'short' });
  const dd = date.toLocaleString('default', { day: '2-digit' });
  const time = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
  return `${yyyy}-${mm}-${dd} ${time}`;
};

export const forecasteHeaderStyle = async (context, sheet, branchDataPeriodLength, approvalTime) => {
  const approvalDate = new Date(approvalTime);
  const today = new Date();
  const approvalDateFormated = forecastDateFormator(approvalDate);
  const todayFormated = forecastDateFormator(today);
  const cellAddress = generateCellAddress(branchDataPeriodLength);
  const currentBranchHeader = sheet.getRange(`${cellAddress.currentBranchStart}:${cellAddress.currentBranchEnd}`);
  const previousBranchHeader = sheet.getRange(`${cellAddress.previousBranchStart}:${cellAddress.previousBranchEnd}`);
  const differenceHeader = sheet.getRange(`${cellAddress.diffStart}:${cellAddress.diffEnd}`);
  currentBranchHeader.merge();
  previousBranchHeader.merge();
  differenceHeader.merge();
  const currentBranchHeadingCell = sheet.getRange(cellAddress.currentBranchStart);
  const previousBranchHeadingCell = sheet.getRange(cellAddress.previousBranchStart);
  const differenceHeadingCell = sheet.getRange(cellAddress.diffStart);
  currentBranchHeadingCell.values = [[`Latest forecast\n ${todayFormated}`]];
  previousBranchHeadingCell.values = [[`Last month's forecast\n ${approvalDateFormated}`]];
  differenceHeadingCell.values = [["Latest vs Last month's forecast\n>1% difference: red; <1% difference: blue"]];

  const centerAlignText = sheet.getRange(`${cellAddress.currentBranchStart}:${cellAddress.diffEnd}`);
  centerAlignText.format.horizontalAlignment = 'Center';
  centerAlignText.format.font.bold = true;

  const bgCells = sheet.getRange(`A1:${cellAddress.diffEnd}`);
  bgCells.format.fill.color = globalVars['--ds-color-london-85'];
  bgCells.format.rowHeight = 30;

  const usedRange = sheet.getUsedRange();
  columnStyleForecastChecker(usedRange, branchDataPeriodLength);
};

export const createRowGrouping = async (sheetName, groupRange) =>
  await wrapWithExcel(createRowGroupingPrivate, sheetName, groupRange);

const createRowGroupingPrivate = async (context, sheetName, groupRange) => {
  let sheet = context.workbook.worksheets.getItem(sheetName);
  sheet.getRange(groupRange).group(Excel.GroupOption.byRows);
  await context.sync();
};

export const createColoumGrouping = async (sheetName, groupRange) =>
  await wrapWithExcel(createColoumGroupingPrivate, sheetName, groupRange);

const createColoumGroupingPrivate = async (context, sheetName, groupRange) => {
  let sheet = context.workbook.worksheets.getItem(sheetName);
  sheet.getRange(groupRange).group(Excel.GroupOption.byColumns);
  await context.sync();
};

export const clearRowGrouping = async (sheetName, groupRange) =>
  await wrapWithExcel(clearRowGroupingPrivate, sheetName, groupRange);

const clearRowGroupingPrivate = async (context, sheetName, groupRange) => {
  let sheet = context.workbook.worksheets.getItem(sheetName);
  sheet.getRange(groupRange).ungroup(Excel.GroupOption.byRows);
  await context.sync();
};

export const clearColoumGrouping = async (sheetName, groupRange) =>
  await wrapWithExcel(clearColoumGroupingPrivate, sheetName, groupRange);

const clearColoumGroupingPrivate = async (context, sheetName, groupRange) => {
  let sheet = context.workbook.worksheets.getItem(sheetName);
  sheet.getRange(groupRange).ungroup(Excel.GroupOption.byColumns);
  await context.sync();
};

export const getActiveWorksheetName = async () => {
  return wrapWithExcel(async context => {
    const sheet = context.workbook.worksheets.getActiveWorksheet();
    sheet.load('name');
    await context.sync();
    return sheet.name;
  });
};
