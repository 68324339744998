/* global */

export const DEV = 'dev';
export const PROD = 'prod';
export const LOCAL = 'local';

export const currentEnvironment = () => {
  if (window.location.hostname.endsWith('dev-forecasting.eiu.com')) {
    return DEV;
  }
  if (window.location.hostname.endsWith('forecasting.eiu.com')) {
    return PROD;
  }
  return LOCAL;
};

export const baseApplicationHostname = () => {
  if (currentEnvironment() === DEV) {
    return 'https://dev-forecasting.eiu.com';
  }
  if (currentEnvironment() === PROD) {
    return 'https://forecasting.eiu.com';
  }
  return 'https://localhost:3020';
};

export const baseApiUrl = () => {
  if (currentEnvironment() === DEV) {
    return 'https://api.dev-forecasting.eiu.com';
  }
  if (currentEnvironment() === PROD) {
    return 'https://api.forecasting.eiu.com';
  }
  return 'https://localhost:3021';
};

export const baseApplicationUrl = () => `${baseApplicationHostname()}/excel`;

export const authenticationUrls = {
  postLoginRedirectUrl: `${baseApplicationUrl()}/login/callback`,
  postLogoutRedirectUrl: `${baseApplicationUrl()}/index`
};
