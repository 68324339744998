import * as React from 'react';
import PropTypes from 'prop-types';

const Error = props => {
  const { message } = props;

  return <div className='eiu-fdp-debugging-info'>{message}</div>;
};

Error.propTypes = {
  message: PropTypes.string
};

export default Error;
