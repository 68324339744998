import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Api from '../../../api/api';
import { UseUIConfig } from '../../../providers/UIConfigProvider/UseUIConfig';
import { UseActionState } from '../../../providers/ActionStateProvider/UseActionState';
import { useLogger } from '../../../providers/LoggingContextProvider/LoggingContextProvider';
import SplashScreen from '../SplashScreen/SplashScreen';
import DialogTrigger from '../DialogTrigger/DialogTrigger';
import { deleteSheets } from '../../util/office-document';

const ForecastApproval = ({ setDebugApprovalStatus }) => {
  const [forecastReadyDetails, setForecastReadyDetails] = useState();
  const [isLoadingForecastReady, setIsLoadingForecastReady] = useState(false);
  const [isLoadingForecastApproved, setIsLoadingForecastApproved] = useState(false);
  const { applicationActionState, setApplicationActionState } = UseActionState();
  const { UIConfig } = UseUIConfig();
  const fdpApplicationlog = useLogger();

  useEffect(() => {
    if (UIConfig && UIConfig.pull_requestId) {
      setForecastReadyDetails({ number: UIConfig.pull_requestId });
      setApplicationActionState({
        ...applicationActionState,
        ...{
          pullRequestAvailable: {
            isOperationAllowed: true
          }
        }
      });
    }
  }, [UIConfig?.pull_requestId]);

  const handleForecastReady = async arg => {
    if (arg.message === 'yes') {
      fdpApplicationlog('log', 'User clicked Yes', {});
      setIsLoadingForecastReady(true);
      setApplicationActionState({
        ...applicationActionState,
        ...{
          taskPaneActions: {
            isOperationAllowed: false
          }
        }
      });
      await Api.forecastReadyForReview({
        branchName: UIConfig.branch,
        inputGeographyCode: applicationActionState.forecastConfiguration.countryCode
      })
        .then(async response => {
          if (response && response.status == 200 && response.data) {
            setForecastReadyDetails(response.data);
            setDebugApprovalStatus({
              readyStatus: `Your forecast is ready for review - ${response.data.number}`
            });
            setApplicationActionState({
              ...applicationActionState,
              ...{
                forecastConfiguration: {
                  ...applicationActionState.forecastConfiguration,
                  forecastState: 'in review'
                },
                getForecastConfig: {
                  isOperationAllowed: false
                },
                updateResiduals: {
                  isOperationAllowed: false
                },
                forecastReadyForReview: {
                  isOperationAllowed: false
                },
                forecastApproved: {
                  isOperationAllowed: true
                },
                taskPaneActions: {
                  isOperationAllowed: true
                }
              }
            });
            if (applicationActionState.featureFlags.IsReviewerWorkflowEnabled) {
              deleteSheets();
            }
          } else {
            if (response.status === 422) {
              setDebugApprovalStatus({
                readyStatus:
                  'Failed to mark a forecast as Ready For Review since residuals match currently published data. Please update more residuals.'
              });
              setApplicationActionState({
                ...applicationActionState,
                ...{
                  forecastConfiguration: {
                    ...applicationActionState.forecastConfiguration,
                    forecastState: 'in draft'
                  },
                  taskPaneActions: {
                    isOperationAllowed: true
                  }
                }
              });
            } else {
              fdpApplicationlog('error', 'Error in submitting forecast', response);
              setDebugApprovalStatus({
                readyStatus: `Something broke when marking a forecast as ready for review. Details: ${JSON.stringify(
                  response
                )}`
              });
              setApplicationActionState({
                ...applicationActionState,
                ...{
                  forecastConfiguration: {
                    ...applicationActionState.forecastConfiguration,
                    forecastState: 'in draft'
                  },
                  taskPaneActions: {
                    isOperationAllowed: true
                  }
                }
              });
            }
          }
        })
        .catch(exception => {
          fdpApplicationlog('error', 'Error in submitting forecast', exception);
          setDebugApprovalStatus({
            readyStatus: `Something broke when marking a forecast as ready for review. Details: ${exception}`
          });
          setApplicationActionState({
            ...applicationActionState,
            ...{
              forecastConfiguration: {
                ...applicationActionState.forecastConfiguration,
                forecastState: 'in draft'
              },
              taskPaneActions: {
                isOperationAllowed: true
              }
            }
          });
        });

      setIsLoadingForecastReady(false);
    } else {
      setIsLoadingForecastReady(false);
    }
  };
  const handleForecastApproved = async arg => {
    if (arg.message === 'yes') {
      fdpApplicationlog('log', 'User clicked Yes', {});
      setIsLoadingForecastApproved(true);
      setApplicationActionState({
        ...applicationActionState,
        ...{
          taskPaneActions: {
            isOperationAllowed: false
          }
        }
      });
      await Api.forecastApproved({
        branchName: UIConfig.branch,
        pullRequestNumber: forecastReadyDetails.number,
        inputGeographyCode: applicationActionState.forecastConfiguration.countryCode
      })
        .then(async response => {
          if (response && response.status == 200 && response.data) {
            setDebugApprovalStatus({
              approveStatus:
                'Your forecast is being published, please wait for some time and then re-fetch the updated data'
            });
            setApplicationActionState({
              ...applicationActionState,
              ...{
                forecastConfiguration: {
                  ...applicationActionState.forecastConfiguration,
                  forecastState: 'approved'
                },
                getForecastConfig: {
                  isOperationAllowed: false
                },
                updateResiduals: {
                  isOperationAllowed: false
                },
                forecastReadyForReview: {
                  isOperationAllowed: false
                },
                pullRequestAvailable: {
                  isOperationAllowed: false
                },
                forecastApproved: {
                  isOperationAllowed: false
                },
                taskPaneActions: {
                  isOperationAllowed: true
                },
                forecastCompleted: true
              }
            });
            deleteSheets();
          } else {
            setDebugApprovalStatus({
              approveStatus: `Your forecast encountered an error while publishing.`
            });
            setApplicationActionState({
              ...applicationActionState,
              ...{
                forecastConfiguration: {
                  ...applicationActionState.forecastConfiguration,
                  forecastState: 'in review'
                },
                taskPaneActions: {
                  isOperationAllowed: true
                }
              }
            });
            fdpApplicationlog('error', 'Error in approving forecast', { response });
          }
        })
        .catch(exception => {
          fdpApplicationlog('error', 'Error in approving forecast', { exception });
          setDebugApprovalStatus({
            approveStatus: `Your forecast encountered an error while publishing.`
          });
          setApplicationActionState({
            ...applicationActionState,
            ...{
              forecastConfiguration: {
                ...applicationActionState.forecastConfiguration,
                forecastState: 'in review'
              },
              taskPaneActions: {
                isOperationAllowed: true
              }
            }
          });
        });
      setIsLoadingForecastApproved(false);
    } else {
      setIsLoadingForecastApproved(false);
    }
  };
  const handleForecastRejected = async arg => {
    if (arg.message === 'yes') {
      fdpApplicationlog('log', 'User clicked Yes', {});
      setIsLoadingForecastApproved(true);
      setApplicationActionState({
        ...applicationActionState,
        ...{
          taskPaneActions: {
            isOperationAllowed: false
          }
        }
      });
      await Api.forecastRejected({
        branchName: UIConfig.branch,
        pullRequestNumber: forecastReadyDetails.number,
        inputGeographyCode: applicationActionState.forecastConfiguration.countryCode
      })
        .then(async response => {
          if (response && response.status == 200 && response.data) {
            setDebugApprovalStatus({
              approveStatus:
                'The forecast has been moved back. This is now back to draft state for the author to work on it.'
            });
            setApplicationActionState({
              ...applicationActionState,
              ...{
                forecastConfiguration: {
                  ...applicationActionState.forecastConfiguration,
                  forecastState: 'draft'
                },
                getForecastConfig: {
                  isOperationAllowed: false
                },
                updateResiduals: {
                  isOperationAllowed: false
                },
                forecastReadyForReview: {
                  isOperationAllowed: false
                },
                pullRequestAvailable: {
                  isOperationAllowed: false
                },
                forecastApproved: {
                  isOperationAllowed: false
                },
                taskPaneActions: {
                  isOperationAllowed: true
                }
              }
            });
            deleteSheets();
          } else {
            setDebugApprovalStatus({
              approveStatus: `Your forecast encountered an error while moving back. Details: ${JSON.stringify(
                response
              )}`
            });
            setApplicationActionState({
              ...applicationActionState,
              ...{
                forecastConfiguration: {
                  ...applicationActionState.forecastConfiguration,
                  forecastState: 'in review'
                },
                taskPaneActions: {
                  isOperationAllowed: true
                }
              }
            });
          }
        })
        .catch(exception => {
          setDebugApprovalStatus({
            approveStatus: `Your forecast encountered an error while publishing. Details: ${exception}`
          });
          setApplicationActionState({
            ...applicationActionState,
            ...{
              forecastConfiguration: {
                ...applicationActionState.forecastConfiguration,
                forecastState: 'in review'
              },
              taskPaneActions: {
                isOperationAllowed: true
              }
            }
          });
          fdpApplicationlog('error', 'Error in moving back forecast', exception);
        });
      setIsLoadingForecastApproved(false);
    } else {
      setIsLoadingForecastApproved(false);
    }
  };
  return (
    <>
      <div>
        {isLoadingForecastReady ? (
          <SplashScreen header='' text='Forecast sent for review.' />
        ) : (
          <>
            {applicationActionState.forecastReadyForReview.isOperationAllowed &&
              applicationActionState.forecastConfiguration.currentMode === 'draft' && (
                <div className='eiu-fdp-forecast-submission'>
                  <DialogTrigger
                    processMessage={handleForecastReady}
                    actionText='Forecast Ready for review'
                    url={`${window.location.origin}/excel/confirmationDialog?type=review`}
                  />
                </div>
              )}
          </>
        )}
      </div>
      {forecastReadyDetails && forecastReadyDetails.number && (
        <div>
          {isLoadingForecastApproved ? (
            <SplashScreen header='' text='Forecast is being approved' />
          ) : (
            <>
              {applicationActionState.pullRequestAvailable?.isOperationAllowed &&
                applicationActionState.taskPaneActions.isOperationAllowed &&
                applicationActionState.forecastConfiguration.currentMode === 'review' && (
                  <div className='eiu-fdp-approve-action-container'>
                    <DialogTrigger
                      processMessage={handleForecastApproved}
                      actionText='Approve'
                      url={`${window.location.origin}/excel/confirmationDialog?type=approve`}
                    />
                    <DialogTrigger
                      processMessage={handleForecastRejected}
                      actionText='Move back'
                      url={`${window.location.origin}/excel/confirmationDialog?type=reject`}
                      secondary
                    />
                  </div>
                )}
              {(applicationActionState.forecastApproved.isOperationAllowed ||
                applicationActionState.pullRequestAvailable?.isOperationAllowed) &&
                applicationActionState.taskPaneActions.isOperationAllowed &&
                !applicationActionState.featureFlags.IsReviewerWorkflowEnabled && (
                  <div className='eiu-fdp-approve-action-container'>
                    <DialogTrigger
                      processMessage={handleForecastApproved}
                      actionText='Approve'
                      url={`${window.location.origin}/excel/confirmationDialog?type=approve`}
                    />
                  </div>
                )}
            </>
          )}
        </div>
      )}
    </>
  );
};

ForecastApproval.propTypes = {
  setDebugApprovalStatus: PropTypes.func
};

export default ForecastApproval;
