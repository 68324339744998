import { useContext } from 'react';
import { UserInfoContext } from './UserInfoProvider';

export const UseUserInfo = () => {
  const context = useContext(UserInfoContext);
  if (!context) {
    throw new Error('UseUserInfo must be used within UserInfoProvider');
  }
  return context;
};
