module.exports = {
  '--ds-color-economist-red': '#E3120B',
  '--ds-color-economist-red-60': '#F6423C',
  '--ds-color-chicago-20': '#141F52',
  '--ds-color-chicago-30': '#1F2E7A',
  '--ds-color-chicago-45': '#2E45B8',
  '--ds-color-chicago-55': '#475ED1',
  '--ds-color-chicago-90': '#D6DBF5',
  '--ds-color-chicago-95': '#EBEDFA',

  '--ds-color-hong-kong-45': '#1DC9A4',
  '--ds-color-hong-kong-55': '#36E2BD',
  '--ds-color-hong-kong-90': '#D2F9F0',
  '--ds-color-hong-kong-95': '#E9FCF8',
  '--ds-color-tokyo-45': '#C91D42',
  '--ds-color-tokyo-55': '#E2365B',
  '--ds-color-tokyo-90': '#F9D2DB',
  '--ds-color-tokyo-95': '#FCE9ED',

  '--ds-color-singapore-55': '#F97A1F',
  '--ds-color-singapore-65': '#FB9851',
  '--ds-color-singapore-75': '#FCB583',
  '--ds-color-singapore-90': '#FEE1CD',
  '--ds-color-new-york-55': '#F9C31F',
  '--ds-color-new-york-65': '#FBD051',
  '--ds-color-new-york-75': '#FCDE83',
  '--ds-color-new-york-90': '#FEF2CD',

  '--ds-color-london-5': '#0D0D0D',
  '--ds-color-london-10': '#1A1A1A',
  '--ds-color-london-20': '#333333',
  '--ds-color-london-35': '#595959',
  '--ds-color-london-70': '#B3B3B3',
  '--ds-color-london-85': '#D9D9D9',
  '--ds-color-london-95': '#F2F2F2',
  '--ds-color-london-100': '#FFFFFF',

  '--ds-color-los-angeles-85': '#E1DFD0',
  '--ds-color-los-angeles-90': '#EBE9E0',
  '--ds-color-los-angeles-95': '#F5F4EF',
  '--ds-color-paris-85': '#D0E1E1',
  '--ds-color-paris-90': '#E0EBEB',
  '--ds-color-paris-95': '#EFF5F5',

  '--ds-color-excel-calculation-mode': '#C25405',

  /* Typography */
  '--ds-type-scale-base': '16px',
  '--ds-type-scale-ratio': 1.125,
  '--ds-type-leading-lower': 1.4,
  '--ds-type-leading-upper': 1.18,

  '--ds-type-scale--2': '0.8125rem' /* 13px */,
  '--ds-type-scale--1': '0.9375rem' /* 15px */,
  '--ds-type-scale-0': '1.0625rem' /* 17px */,
  '--ds-type-scale-1': '1.25rem' /* 20px */,
  '--ds-type-scale-2': '1.4375rem' /* 23px */,
  '--ds-type-scale-3': '1.625rem' /* 26px */,
  '--ds-type-scale-4': '1.875rem' /* 30px */,
  '--ds-type-scale-5': '2.125rem' /* 34px */,
  '--ds-type-scale-6': '2.5rem' /* 40px */,
  '--ds-type-scale-7': '2.875rem' /* 46px */,
  '--ds-type-scale-8': '3.25rem' /* 52px */,
  '--ds-type-scale-9': '3.75rem' /* 60px */,
  '--ds-type-scale-10': '4.25rem' /* 68px */,
  '--ds-type-scale-11': '5rem' /* 80px */,
  '--ds-type-scale-12': '5.75rem' /* 92px */
};
