import { useContext } from 'react';
import { ResidualChangeContext } from './ResidualChangeProvider';

//Note Residual here also refers to tunes (Judgements)
export const UseResidualChange = () => {
  const context = useContext(ResidualChangeContext);
  if (!context) {
    throw new Error('UseResidualChange must be used within ResidualChangeProvider');
  }
  return context;
};
