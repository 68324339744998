import axios from 'axios';
import { baseApiUrl } from '../config/urlConstants';

axios.defaults.headers.common['Content-Type'] = 'text/plain';
export default class Api {
  static getForecastConfiguration = (geographyCode, forecastType, branchName) =>
    Api.get(`${baseApiUrl()}/Forecastconfiguration/geography/${geographyCode}/forecast/${forecastType}/${branchName}`);

  static getForecastData = request => Api.post(`${baseApiUrl()}/DataWarehouse/data/search`, request);

  static init = (geographyCode, forecastType, mode, branch) => {
    const url = `${baseApiUrl()}/ForecastConfiguration/geography/${geographyCode}/forecast/${forecastType}/init/${mode}`;
    const fullUrl = branch ? `${url}?branch=${branch}` : url;
    return Api.post(fullUrl);
  };

  static setUp = () => Api.post(`${baseApiUrl()}/ForecastConfiguration/setup`);

  static cleanUp = (geographyCode, forecastType) =>
    Api.post(`${baseApiUrl()}/ForecastConfiguration/geography/${geographyCode}/forecast/${forecastType}/cleanup`);

  static updateResiduals = (geographyCode, forecastType, request) =>
    Api.post(
      `${baseApiUrl()}/Forecastconfiguration/geography/${geographyCode}/forecast/${forecastType}/residuals`,
      request
    );

  static forecastRunStatus = (runStatusId, inputGeographyCode) =>
    Api.get(`${baseApiUrl()}/ForecastConfiguration/forecast/status/${inputGeographyCode}/${runStatusId}`);

  static forecastReadyForReview = request => Api.post(`${baseApiUrl()}/Forecastconfiguration/review/ready`, request);

  static forecastApproved = request => Api.post(`${baseApiUrl()}/Forecastconfiguration/review/merge`, request);

  static forecastRejected = request => Api.post(`${baseApiUrl()}/Forecastconfiguration/review/reject`, request);

  static getLatestSeriesPeriods = (geographyCode, forecastType) =>
    Api.get(
      `${baseApiUrl()}/Forecastconfiguration/geography/${geographyCode}/forecast/${forecastType}/latest-series-periods`
    );

  static judgementCleanup = (geographyCode, forecastType, request) =>
    Api.post(
      `${baseApiUrl()}/Forecastconfiguration/geography/${geographyCode}/forecast/${forecastType}/judgement-cleanup`,
      request
    );

  static deleteWarehouseBranch = branchName =>
    Api.delete(`${baseApiUrl()}/DataWarehouse/branch?branchName=${branchName}`);

  static getForecastPublishStatus = geographyCode =>
    Api.get(`${baseApiUrl()}/ForecastConfiguration/forecast/publish-status/${geographyCode}`);

  static get = (url, options = {}) => axios.get(url, options);
  static post = (url, content = undefined, options = {}) => axios.post(url, content, options);
  static delete = (url, content = {}, options = {}) => axios.delete(url, content, options);
}
