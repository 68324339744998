import React, { useEffect } from 'react';
import { FormRadioButton } from '@economist/design-system/dist/umd/common';
import { UseActionState } from '../../../providers/ActionStateProvider/UseActionState';
import './ForecastSelection.css';
import { defaultMode } from '../../util/constants';

const ForecastModeSelector = () => {
  const { applicationActionState, setApplicationActionState } = UseActionState();
  useEffect(() => {
    let defaultModeCheck;
    if (applicationActionState.forecastConfiguration.forecastState === 'published') {
      defaultModeCheck = 'draft';
    } else {
      defaultModeCheck = applicationActionState.forecastConfiguration.forecastState;
    }
    setApplicationActionState({
      ...applicationActionState,
      forecastConfiguration: {
        ...applicationActionState.forecastConfiguration,
        forecastState: defaultModeCheck,
        currentMode: defaultModeCheck || defaultMode
      }
    });
  }, []);

  const handleModeChange = e => {
    const currentForecastState = applicationActionState.forecastConfiguration.forecastState;
    setApplicationActionState({
      ...applicationActionState,
      forecastConfiguration: {
        ...applicationActionState.forecastConfiguration,
        forecastState: currentForecastState === 'published' ? 'draft' : currentForecastState,
        currentMode: e.target.value
      }
    });
  };
  return (
    <div className='eiu-fdp-forecast-modes'>
      <div className='eiu-fdp-forecast-mode-header'>
        <p>Format</p>
      </div>
      <div>
        <FormRadioButton
          labelText='Edit'
          id='draft'
          value='draft'
          name='forecastMode'
          disabled={!['draft', 'published'].includes(applicationActionState.forecastConfiguration.forecastState)}
          onChange={handleModeChange}
          checked={applicationActionState.forecastConfiguration.currentMode === 'draft'}
        />
        <FormRadioButton
          labelText='Review'
          id='review'
          value='review'
          name='forecastMode'
          disabled={!['review'].includes(applicationActionState.forecastConfiguration.forecastState)}
          checked={applicationActionState.forecastConfiguration.currentMode === 'review'}
          onChange={handleModeChange}
        />
        <FormRadioButton
          labelText='Read only'
          id='read'
          value='read'
          name='forecastMode'
          onChange={handleModeChange}
          checked={applicationActionState.forecastConfiguration.currentMode === 'read'}
        />
      </div>
    </div>
  );
};

ForecastModeSelector.propTypes = {};

export default ForecastModeSelector;
