import React, { useState } from 'react';
import PropTypes from 'prop-types';

const defaultVal = {};
export const UserInfoContext = React.createContext(defaultVal);

export const UserInfoProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState();

  return <UserInfoContext.Provider value={{ userDetails, setUserDetails }}>{children}</UserInfoContext.Provider>;
};

UserInfoProvider.propTypes = {
  children: PropTypes.node.isRequired
};
