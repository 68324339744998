import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import logo from '../../../../assets/logo-filled.png';
import { UseUserInfo } from '../../../providers/UserInfoProvider/UseUserInfo';
import { NavigationDisclosureIcon, NavigationLink } from '@economist/design-system/dist/umd/common';

const Header = props => {
  const { userDetails } = UseUserInfo();
  const [showuserInfo, setShowUserInfo] = useState(!!userDetails);
  const [showLogoutOption, setShowLogoutOption] = useState(false);

  useEffect(() => {
    setShowUserInfo(!!userDetails);
  }, [userDetails]);

  return (
    <section className='eiu-fdp-add-in_header'>
      <div className='eiu-fdp-logo-container'>
        <img src={logo} className='excel-landing-screen_logo' />
      </div>
      {showuserInfo && (
        <div className='eiu-fdp-userinfo'>
          <NavigationDisclosureIcon
            icon='account'
            text={props.userDisplayName}
            expanded={showLogoutOption}
            onClick={e => {
              e.preventDefault();
              setShowLogoutOption(!showLogoutOption);
            }}
          />
          {showLogoutOption && <NavigationLink text='Logout' href='#' onClick={props.logout} className='logout-link' />}
        </div>
      )}
    </section>
  );
};

Header.propTypes = {
  userDisplayName: PropTypes.string,
  logout: PropTypes.func
};

export default Header;
