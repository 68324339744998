import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import SplashScreen from '../SplashScreen/SplashScreen';

const LoginPopup = () => {
  const { oktaAuth } = useOktaAuth();
  oktaAuth.signInWithRedirect();
  return (
    <div id='LoginPopup'>
      <SplashScreen header='Redirecting to OKTA login...' />
    </div>
  );
};

export default LoginPopup;
