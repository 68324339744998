export const JudgementType = Object.freeze({
  RESIDUAL: Symbol('residual'),
  TUNE: Symbol('tune'),
  NOT_JUDGEMENT: Symbol('not_judgement')
});

export const Frequency = Object.freeze({
  MONTHLY: Symbol('monthly'),
  QUARTERLY: Symbol('quarterly'),
  ANNUAL: Symbol('annual')
});

//The raw string values of the frequency object for easy iteration
export const PossibleFrequencies = Object.freeze(
  [Frequency.MONTHLY, Frequency.QUARTERLY, Frequency.ANNUAL].map(frequency => frequency.description)
);

export const availableForecasts = [
  { label: 'Select a geography', value: 'none' },
  { label: 'Canada', value: 'ca' }
];

export const wipForecastsBehindFeatureFlag = [
  { label: 'Egypt', value: 'eg' },
  { label: 'France', value: 'fr' },
  { label: 'Indonesia', value: 'id' },
  { label: 'Mexico', value: 'mx' },
  { label: 'United States of America', value: 'us' }
];

export const defaultMode = 'draft';

export const standardModellingDoc =
  'https://docs.google.com/document/d/1YsC8cEFjyc6UTH_AwLswDfNk6opPepIl5HeEoWeHvtY/edit?usp=sharing';
