import React from 'react';
import { NavigationLink } from '@economist/design-system/dist/umd/common';
import { UseActionState } from '../../../providers/ActionStateProvider/UseActionState';
import { useLogger } from '../../../providers/LoggingContextProvider/LoggingContextProvider';
import { DataAQMConfig } from '../UiConfigGenerator/DataAQMConfig';
import { calcuateFrequencyRange } from '../../util/FrequencyCalculation';
import { UseUIConfig } from '../../../providers/UIConfigProvider/UseUIConfig';
import { deleteSheets, setActiveSheet } from '../../util/excel-utils/sheet-operations';

import { writeDataAQMToNewSheets } from '../../util/DataModifierAQM';
import { standardModellingDoc } from '../../util/constants';
import Api from '../../../api/api';
import PropTypes from 'prop-types';

const LinksTaskpane = ({ setIsDataFetchLoading }) => {
  const { applicationActionState } = UseActionState();
  const { UIConfig } = UseUIConfig();
  const fdpApplicationlog = useLogger();
  const modellingDoc = applicationActionState?.featureFlags?.IsReviewerWorkflowEnabled
    ? UIConfig?.ui?.modelDocumentation
    : standardModellingDoc;

  const openForecast = async () => {
    const sheetDeletes = await deleteSheets();
    console.log('sheetDeletes', sheetDeletes);
    location.reload();
  };

  const getAQMData = () => {
    setIsDataFetchLoading(true);
    const sheetDataRequests = [];
    const includeMetadata = true;
    const AQMConfig = DataAQMConfig(applicationActionState.forecastConfiguration.countryCode);
    AQMConfig.ui.sheets.forEach(sheet => {
      const version = [UIConfig.branch];
      const frequency = sheet.type;
      const calculatedDateRange = calcuateFrequencyRange(sheet.daterange, frequency);
      const fromDate = calculatedDateRange.fromDate;
      const toDate = calculatedDateRange.toDate;
      const sheetName = sheet.displayName;
      const seriesUrns = [];
      let requests = [];
      sheet.sections.forEach(section => {
        section.series.forEach(series => {
          if (series.seriesId) {
            if (series.seriesId.value && series.seriesId.value !== 'NO_URN_FOUND') {
              seriesUrns.push(series.seriesId.value);
            }
          }
        });
      });
      requests.push({
        frequency,
        toDate,
        fromDate,
        seriesUrns
      });
      sheetDataRequests.push({
        version,
        includeMetadata,
        sheetName,
        searchData: requests.map(requestItem => {
          return {
            ...requestItem,
            seriesUrns: [...new Set(requestItem.seriesUrns)]
          };
        })
      });
    });

    const annualAQMSheet = AQMConfig.ui.sheets[0].displayName;
    sheetDataRequests.forEach(el => {
      Api.getForecastData(el)
        .then(async response => {
          if (response && response.status == 200 && response.data && response.data.length) {
            await writeDataAQMToNewSheets(response.data, AQMConfig);
            setIsDataFetchLoading(false);
            if ('annual' in response.data[0]) {
              await setActiveSheet(annualAQMSheet);
            }
          } else {
            setIsDataFetchLoading(false);
            fdpApplicationlog('error', 'error Data A, Q, M', response);
          }
        })
        .catch(error => {
          setIsDataFetchLoading(false);
          fdpApplicationlog('error', 'Error fetching data', error);
        });
    });
  };

  return (
    <>
      <div>
        <NavigationLink href={modellingDoc} text='Model documentation' target='_blank' disabled={!modellingDoc} />
      </div>
      <div>
        <NavigationLink href='#' text='Update data' disabled />
      </div>
      <div>
        <NavigationLink
          href='#'
          text='Open forecast'
          onClick={e => {
            e.preventDefault();
            openForecast();
          }}
        />
      </div>
      <div>
        <NavigationLink
          href='#'
          text='See data A, Q, M'
          onClick={e => {
            e.preventDefault();
            getAQMData();
          }}
          disabled={
            !applicationActionState?.featureFlags?.IsReviewerWorkflowEnabled ||
            applicationActionState?.forecastCompleted
          }
        />
      </div>
    </>
  );
};

LinksTaskpane.propTypes = {
  setIsDataFetchLoading: PropTypes.func
};

export default LinksTaskpane;
