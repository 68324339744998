import { getDateFromRelativeDate, getFrequencyMonthsIncrement } from './FrequencyCalculation';
import { PossibleFrequencies } from './constants';
import { getFrequencyAdjustedSeriesName, getSheetFrequencies } from './uiConfigHelper';
import globalVars from '../../styles/dsvars';
import { convertToQuarterlySheetHeaderFormat } from './date_utils';

// this will allow for original objects not getting manipulated specially cases where reassigning configs and search response.
export const deepCopyObject = obj => JSON.parse(JSON.stringify(obj));

export const extractResponseByBranchName = (response, branchName) => {
  return response.data.find(elm => elm.version === branchName);
};

export const judgementSheetBuilder = (
  filteredResponse,
  models,
  allJudgementUpdates,
  latestSeriesPeriods,
  countryName
) => {
  // console.log(
  //   'judgementSheetBuilder hit - writing current allJudgementUpdates:',
  //   allJudgementUpdates,
  //   latestSeriesPeriods
  // );

  let modelJudgements = {};

  models.forEach(model => {
    const mappingList = deepCopyObject(model.mapping.mapping);
    const residualList = deepCopyObject(model.residuals.residuals);
    const manifestList = deepCopyObject(model.manifest.manifest);

    const judgementSeriesList = [];
    const presentFrequencies = PossibleFrequencies.map(frequency =>
      filteredResponse[frequency]?.period?.length ? frequency : undefined
    ).filter(frequency => frequency !== undefined);

    if (manifestList.input_series) {
      manifestList.input_series.forEach(judgementName => {
        const tuneData = manifestList.exogenize_endogenize[judgementName];
        const adjustedJudgementName = tuneData ? judgementName : `${judgementName}_res`;

        const baseSeriesJudgement = {
          model: model.model,
          name: adjustedJudgementName,
          frequencies: {},
          type: tuneData ? 'tune' : 'residual'
        };

        if (tuneData) {
          baseSeriesJudgement.endogenize = tuneData[0];
        }

        presentFrequencies.forEach((frequency, frequenciesIndex) => {
          const frequencyAdjustedSeriesName =
            frequency === 'annual' && !adjustedJudgementName.startsWith('yy_')
              ? `yy_${adjustedJudgementName}`
              : adjustedJudgementName;
          const seriesNameForJudgementUpdates =
            frequency === 'annual' && presentFrequencies.length === 1
              ? frequencyAdjustedSeriesName
              : adjustedJudgementName;

          const judgementFrequencyData = {
            id: mappingList.find(mapItem => mapItem.name === frequencyAdjustedSeriesName)?.urn,
            date: [],
            value: [],
            status: [],
            cell: []
          };

          if (tuneData) {
            // manifestList.exogenize_transforms[adjustedJudgementName] = ["pct", "annual_pct"];
            const transformationIndex = Math.min(
              frequenciesIndex,
              manifestList.exogenize_transforms[adjustedJudgementName]?.length ?? 0 - 1
            );

            const tuneTransformation = manifestList.exogenize_transforms[adjustedJudgementName]?.[transformationIndex];
            if (tuneTransformation && tuneTransformation !== 'none') {
              judgementFrequencyData.transform = tuneTransformation;
            }
          }

          const seriesPeriods = getJudgementPeriods(
            latestSeriesPeriods,
            `${countryName}.${model.model}`,
            judgementName,
            manifestList.dates.end_year_simulation,
            frequency,
            baseSeriesJudgement.type,
            presentFrequencies
          );

          seriesPeriods.forEach(period => {
            const analystJudgementUpdate = allJudgementUpdates.find(
              x => x.period === period && x.name === seriesNameForJudgementUpdates
            );

            const configJudgementUpdate = residualList.find(x => x.date === period && x.name === adjustedJudgementName);
            const isValidJudgementType =
              (!isNaN(analystJudgementUpdate?.value) && !isNaN(parseFloat(analystJudgementUpdate?.value))) ||
              analystJudgementUpdate?.value === '';
            const periodValue = isValidJudgementType ? analystJudgementUpdate.value : configJudgementUpdate?.value;

            judgementFrequencyData.date.push(period);
            judgementFrequencyData.status.push(tuneData ? 't' : 'j');
            judgementFrequencyData.value.push(periodValue ?? null);
          });
          baseSeriesJudgement.frequencies[frequency] = judgementFrequencyData;
        });
        judgementSeriesList.push(baseSeriesJudgement);
      });
    }
    modelJudgements[model.model] = judgementSeriesList;
  });

  // console.log('DataModifier judgements built for models', models, modelJudgements);
  return modelJudgements;
};

export const getJudgementPeriods = (
  latestSeriesPeriods,
  model,
  seriesName,
  modelEndDate,
  frequencyType,
  judgementType,
  presentFrequencies
) => {
  const judgementPeriods = [];
  const startPeriod = latestSeriesPeriods.modelSeriesLatestPeriods?.[model]?.[seriesName];
  const isMultiFrequencySheet = presentFrequencies.length > 1;

  if (judgementType === 'residual' && frequencyType === 'annual' && isMultiFrequencySheet) {
    return judgementPeriods;
  }
  if (modelEndDate[0] !== 'relative_year' || !modelEndDate[1] || !startPeriod) {
    console.warn(
      `Mismatched judgement configuration for series ${seriesName} model ${model}`,
      latestSeriesPeriods,
      modelEndDate,
      frequencyType
    );
    return judgementPeriods;
  }

  const startDate = getJudgementSeriesStartPeriod(startPeriod, frequencyType, judgementType);
  const endDate = getJudgementSeriesEndPeriod(
    modelEndDate[1],
    frequencyType,
    judgementType,
    startPeriod.split('-Q')[0]
  );

  if (startDate >= endDate) {
    return judgementPeriods;
  }

  const frequencyMonthsIncrement = getFrequencyMonthsIncrement(frequencyType);
  let currentDate = new Date(startDate);

  while (currentDate < endDate) {
    const annualPeriodAdjusted =
      frequencyType === 'annual' && isMultiFrequencySheet
        ? `${currentDate.getFullYear()}-Q4`
        : `${currentDate.getFullYear()}`;
    const currentPeriod =
      frequencyType === 'annual'
        ? annualPeriodAdjusted
        : `${currentDate.getFullYear()}-Q${Math.floor(currentDate.getMonth() / 3) + 1}`;
    judgementPeriods.push(currentPeriod);

    currentDate.setMonth(currentDate.getMonth() + frequencyMonthsIncrement);
  }

  return judgementPeriods;
};

const getJudgementSeriesStartPeriod = (startPeriod, frequencyType, judgementType) => {
  const splitPeriod = startPeriod.split('-Q');
  const startYear = splitPeriod[0];
  const startQuarter = splitPeriod[1];
  const startMonth = startQuarter ? (startQuarter - 1) * 3 : 0;

  const adjustedStartDate =
    frequencyType === 'annual' || (judgementType === 'tune' && startQuarter === 4)
      ? `${parseInt(startYear) + 1}`
      : startYear;

  const adjustStartMonthQuarterly = frequencyType === 'quarterly' && startQuarter !== 4 ? startMonth + 3 : 0;

  return new Date(adjustedStartDate, adjustStartMonthQuarterly, 1, 0, 0, 0);
};

const getJudgementSeriesEndPeriod = (modelEndPeriod, frequencyType, judgementType, originalStartYear) => {
  const endDateMonths = modelEndPeriod * getFrequencyMonthsIncrement('annual');
  const endDate = getDateFromRelativeDate(endDateMonths, 'annual');

  if (judgementType === 'tune' && frequencyType === 'quarterly') {
    endDate.setFullYear(originalStartYear);
  }

  return endDate;
};

export const DataModifierByConfigForSheet = (
  response,
  sheetUIConfig,
  sheetModels,
  allJudgementUpdates,
  latestSeriesPeriods,
  versionName,
  sheetMetaData,
  countryCode = ''
) => {
  const startTime = new Date().getTime();
  const countryName = countryCode;
  const branchName = versionName[0];
  const filteredResponse = extractResponseByBranchName(response, branchName);
  const modelJudgementsForSheet = judgementSheetBuilder(
    filteredResponse,
    sheetModels,
    allJudgementUpdates,
    latestSeriesPeriods,
    countryName
  );

  const printData = [];
  const styleData = [];
  const seriesStyle = [];
  const seriesIdList = [];

  const sheetName = sheetUIConfig.displayName;

  if (sheetUIConfig.type === 'forecast_checker') {
    const forecastCheckerPrintData = GetModifiedDataForForecastChecker(
      response,
      sheetUIConfig,
      branchName,
      versionName[1] ? versionName[1] : branchName // Safe check for edge case
    );
    return {
      printData: forecastCheckerPrintData.printData,
      styleData: forecastCheckerPrintData.styleData,
      seriesStyle: forecastCheckerPrintData.seriesStyle,
      modelJudgementValues: modelJudgementsForSheet,
      sheetName,
      seriesIdList
    };
  }

  const quarterlyData = filteredResponse.quarterly;
  const annualData = filteredResponse.annual;
  const sheetFrequencies = getSheetFrequencies(sheetUIConfig.frequencies);

  // add 1 to ensure there is a gap between quarterly and annual only when quarterly is present
  const quarterlyPeriodLength = quarterlyData ? quarterlyData.period.length + 1 : 0;
  const annualPeriodLength = annualData ? annualData.period.length : 0;
  const fullPeriodLength = quarterlyPeriodLength + annualPeriodLength;

  const emptyQuarterlyRowValues = new Array(quarterlyPeriodLength).fill(null);
  const emptyAnnualRowValues = new Array(annualPeriodLength).fill(null);
  const emptyRowValues = new Array(fullPeriodLength).fill(null);

  let previousTitle = '';

  let quartelySeriesPeriods = quarterlyData?.period ? quarterlyData.period?.concat([null]) : [];
  if (quartelySeriesPeriods.length) {
    quartelySeriesPeriods = quartelySeriesPeriods.map(item => {
      return convertToQuarterlySheetHeaderFormat(item);
    });
  }

  const annualSeriesPeriods = annualData?.period ?? [];
  const seriesNames = quartelySeriesPeriods.concat(annualSeriesPeriods);

  printData.push(['Series name', ...seriesNames]);
  styleData.push(['Series name', ...seriesNames]);
  seriesStyle.push({
    indentLevel: 0,
    underline: 'None',
    isBold: true,
    backgroundColor: globalVars['--ds-color-london-85']
  });
  let rowCount = 2;

  sheetUIConfig.sections?.map(section => {
    if (section.title && section.title !== previousTitle) {
      printData.push([section.title, ...emptyRowValues]);
      styleData.push([section.title, ...emptyRowValues]);
      seriesStyle.push({ indentLevel: 0, underline: 'None', isBold: !section.isItalic, isItalic: section.isItalic });
      rowCount++;
      previousTitle = section.title;
    }
    if (section.subtitle) {
      printData.push([section.subtitle, ...emptyRowValues]);
      styleData.push([section.subtitle, ...emptyRowValues]);
      seriesStyle.push({ indentLevel: 0, underline: 'Single', isBold: false });
      rowCount++;
    }

    section.series?.forEach(configSeries => {
      const indentLevel = configSeries.indentLevel || 0;

      if (configSeries.judgement && section.model.length > 0) {
        const judgementSeries = modelJudgementsForSheet[section.model[0]].find(
          judgementSeries => judgementSeries.name === getFrequencyAdjustedSeriesName(sheetUIConfig, configSeries.name)
        );

        const quarterlyValues = judgementSeries?.frequencies?.quarterly
          ? quarterlyData.period
              .map(period => {
                const periodIndex = judgementSeries?.frequencies?.quarterly.date.indexOf(period);
                return periodIndex > -1 ? judgementSeries?.frequencies?.quarterly.value[periodIndex] : null;
              })
              .concat([null])
          : emptyQuarterlyRowValues;
        const annualValues = judgementSeries?.frequencies?.annual
          ? annualData.period.map(period => {
              const annualPeriodAdjusted = sheetFrequencies.length > 1 ? `${period}-Q4` : period;

              const periodIndex = judgementSeries?.frequencies?.annual.date.indexOf(annualPeriodAdjusted);
              return periodIndex > -1 ? judgementSeries?.frequencies?.annual.value[periodIndex] : null;
            })
          : emptyAnnualRowValues;

        const quarterlyStatus = judgementSeries?.frequencies?.quarterly
          ? quarterlyData.period
              .map(period => {
                const periodIndex = judgementSeries?.frequencies?.quarterly.date.indexOf(period);
                return periodIndex > -1 ? judgementSeries?.frequencies?.quarterly.status[periodIndex] : null;
              })
              .concat([null])
          : emptyQuarterlyRowValues;
        const annualStatus = judgementSeries?.frequencies?.annual
          ? annualData.period.map(period => {
              const annualPeriodAdjusted = sheetFrequencies.length > 1 ? `${period}-Q4` : period;

              const periodIndex = judgementSeries?.frequencies?.annual.date.indexOf(annualPeriodAdjusted);
              return periodIndex > -1 ? judgementSeries?.frequencies?.annual.status[periodIndex] : null;
            })
          : emptyAnnualRowValues;

        const joinedSeriesIds = sheetFrequencies
          .map(frequency => judgementSeries?.frequencies[frequency]?.id)
          .join(',');

        if (judgementSeries) {
          printData.push([configSeries.displayName, ...quarterlyValues, ...annualValues]);
          styleData.push([joinedSeriesIds, ...quarterlyStatus, ...annualStatus]);
          seriesStyle.push({ indentLevel: indentLevel, underline: 'None', isBold: false, type: judgementSeries.type });
          rowCount++;
        }
      } else {
        let currentQuarterlySeries = configSeries.seriesId?.quarterly
          ? GetSeries(configSeries.seriesId?.quarterly, quarterlyData)
          : null;
        if (currentQuarterlySeries)
          currentQuarterlySeries = {
            ...currentQuarterlySeries,
            ...sheetMetaData[currentQuarterlySeries.seriesId]
          };
        let currentAnnualSeries = configSeries.seriesId?.annual
          ? GetSeries(configSeries.seriesId?.annual, annualData)
          : null;
        if (currentAnnualSeries)
          currentAnnualSeries = {
            ...currentAnnualSeries,
            ...sheetMetaData[currentAnnualSeries.seriesId]
          };

        const isBold =
          (currentAnnualSeries?.publicationCode ? currentAnnualSeries?.publicationCode.length : null) > 0 ||
          (currentQuarterlySeries?.publicationCode ? currentQuarterlySeries.publicationCode.length : null) > 0
            ? true
            : false;
        seriesStyle.push({ indentLevel: indentLevel, underline: 'None', isBold: isBold });

        let seriesQuarterlyValues =
          currentQuarterlySeries && currentQuarterlySeries.values && currentQuarterlySeries.values.length > 0
            ? currentQuarterlySeries.values
            : emptyQuarterlyRowValues;

        //if quartely data doesn't populate all periods ensure the rest are empty (to keep following data aligned) will add the empty value on end to provide gap with annual.
        if (seriesQuarterlyValues.length != emptyQuarterlyRowValues.length) {
          seriesQuarterlyValues = seriesQuarterlyValues.concat(
            Array(emptyQuarterlyRowValues.length - seriesQuarterlyValues.length).fill(null)
          );
        }

        const seriesAnnualVals =
          currentAnnualSeries && currentAnnualSeries.values && currentAnnualSeries.values.length > 0
            ? currentAnnualSeries.values
            : emptyAnnualRowValues;

        const seriesVals = seriesQuarterlyValues.concat(seriesAnnualVals);

        printData.push([
          getSeriesDisplayName(configSeries, currentAnnualSeries, currentQuarterlySeries),
          ...seriesVals
        ]);
        seriesIdList.push({
          row: rowCount,
          seriesId: configSeries.seriesId,
          model: section.model[0],
          annualPeriod: filteredResponse['annual']?.period,
          quarterlyPeriod: filteredResponse['quarterly']?.period
        });

        rowCount++;
        let quartelySeriesStatus =
          currentQuarterlySeries && currentQuarterlySeries.values && currentQuarterlySeries.values.length > 0
            ? currentQuarterlySeries.status
            : emptyQuarterlyRowValues;

        //if quartely data doesn't populate all SeriesSatus ensure the rest are empty (to keep following data aligned) will add the empty value on end to provide gap with annual.
        if (quartelySeriesStatus && quartelySeriesStatus.length != emptyQuarterlyRowValues.length) {
          quartelySeriesStatus = quartelySeriesStatus.concat(
            Array(emptyQuarterlyRowValues.length - quartelySeriesStatus.length).fill(null)
          );
        }

        const annualSeriesStatus =
          currentAnnualSeries && currentAnnualSeries.values && currentAnnualSeries.values.length > 0
            ? currentAnnualSeries.status
            : emptyAnnualRowValues;

        const seriesStatus = quartelySeriesStatus?.concat(annualSeriesStatus);

        styleData.push([configSeries.displayName, ...seriesStatus]);
      }
    });
    printData.push(['', ...emptyRowValues]);
    styleData.push(['', ...emptyRowValues]);
    seriesStyle.push({ indentLevel: 0, underline: 'None', isBold: false });
    rowCount++;
  });

  const endTime = new Date().getTime();
  console.log(`Time taken: The function DataModifierByConfigForSheet took ${endTime - startTime} to complete`);

  return { printData, styleData, seriesStyle, modelJudgementValues: modelJudgementsForSheet, sheetName, seriesIdList };
};

const GetSeries = (series, data) => {
  return data?.series ? data.series?.find(judgementSeries => judgementSeries.seriesId === series) : null;
};

const GetModifiedDataForForecastChecker = (response, sheetUIConfig, currentBranch, snapshotId) => {
  const printData = [];
  const styleData = [];
  const seriesStyle = [];
  const previousAnnualData = extractResponseByBranchName(response, snapshotId).annual;
  const currentAnnualData = extractResponseByBranchName(response, currentBranch).annual;

  const previousPeriodLength = previousAnnualData ? previousAnnualData.period.length + 1 : 0;
  const currentPeriodLength = currentAnnualData ? currentAnnualData.period.length + 1 : 0;
  const differencePeriodLength = currentAnnualData ? currentAnnualData.period.length : 0;

  const previousAnnualSeriesPeriods = previousAnnualData.period?.concat([null]) ?? [];
  const currentAnnualSeriesPeriods = [null].concat(currentAnnualData?.period)?.concat([null]) ?? [];
  const differenceSeriesPeriods = currentAnnualData?.period ?? [];

  const emptyPreviousRowValues = new Array(previousPeriodLength).fill(null);
  const emptyCurrentRowValues = new Array(currentPeriodLength).fill(null);
  const fullPeriodLength = previousPeriodLength + currentPeriodLength + differencePeriodLength;

  const emptyRowValues = new Array(fullPeriodLength).fill(null);

  const seriesPeriods = currentAnnualSeriesPeriods.concat(previousAnnualSeriesPeriods).concat(differenceSeriesPeriods);
  printData.push(['Forecast checker', null, ...emptyRowValues]);
  printData.push(['', ...seriesPeriods]);

  styleData.push(['Forecast checker', null, ...emptyRowValues]);
  styleData.push(['', null, ...emptyRowValues]);

  seriesStyle.push({
    indentLevel: 0,
    underline: 'None',
    isBold: true,
    branchDataPeriodLength: currentAnnualData.period.length
  });
  seriesStyle.push({ indentLevel: 0, underline: 'None', isBold: true });

  sheetUIConfig.sections.map(section => {
    if (section.title) {
      printData.push([section.title, ...emptyRowValues]);
      seriesStyle.push({ indentLevel: 0, underline: 'None', isBold: !section.isItalic });
      styleData.push([section.title, ...emptyRowValues]);
    }
    if (section.subtitle) {
      printData.push([section.subtitle, ...emptyRowValues]);
      seriesStyle.push({ indentLevel: 0, underline: 'None', isBold: false });
      styleData.push([section.subtitle, ...emptyRowValues]);
    }

    section.series.forEach(configSeries => {
      const previousAnnualSeries = configSeries.seriesId?.annual
        ? GetSeries(configSeries.seriesId?.annual, previousAnnualData)
        : null;

      const currentAnnualSeries = configSeries.seriesId?.annual
        ? GetSeries(configSeries.seriesId?.annual, currentAnnualData)
        : null;

      let previousAnnualValues =
        previousAnnualSeries && previousAnnualSeries.values && previousAnnualSeries.values.length > 0
          ? previousAnnualSeries.values
          : emptyPreviousRowValues;

      let currentAnnualVals =
        currentAnnualSeries && currentAnnualSeries.values && currentAnnualSeries.values.length > 0
          ? currentAnnualSeries.values
          : emptyCurrentRowValues;

      if (currentAnnualVals.length != emptyCurrentRowValues.length) {
        currentAnnualVals = currentAnnualVals.concat(
          Array(emptyCurrentRowValues.length - currentAnnualVals.length).fill(null)
        );
      }

      if (previousAnnualValues.length != emptyPreviousRowValues.length) {
        previousAnnualValues = previousAnnualValues.concat(
          Array(emptyPreviousRowValues.length - previousAnnualValues.length).fill(null)
        );
      }

      const differences = [];
      const cellStyleType = new Array(currentAnnualVals.length + previousAnnualValues.length).fill(null);
      for (let i = 0; i < currentAnnualVals.length - 1; i++) {
        const valueDifference = (
          parseFloat(currentAnnualVals[i]).toFixed(2) - parseFloat(previousAnnualValues[i]).toFixed(2)
        ).toFixed(2);
        const percentageDifference = (valueDifference / parseFloat(currentAnnualVals[i]).toFixed(2)) * 100;
        differences.push(valueDifference == 0 ? '' : valueDifference);
        const absPercentDifference = Math.abs(percentageDifference);
        if (absPercentDifference && absPercentDifference > 0 && absPercentDifference <= 1) {
          cellStyleType.push('b');
        } else if (absPercentDifference > 1) {
          cellStyleType.push('r');
        } else {
          cellStyleType.push(null);
        }
      }

      const seriesVals = currentAnnualVals.concat(previousAnnualValues).concat(differences);

      printData.push([
        getSeriesDisplayName(configSeries, currentAnnualSeries, previousAnnualSeries),
        null,
        ...seriesVals
      ]);
      seriesStyle.push({ indentLevel: 0, underline: 'None', isBold: false });
      styleData.push([
        getSeriesDisplayName(configSeries, currentAnnualSeries, previousAnnualSeries),
        null,
        ...cellStyleType
      ]);
    });
    printData.push(['', null, ...emptyRowValues]);
  });

  return { printData, styleData, seriesStyle };
};

export const getSeriesDisplayName = (configSeries, annualDataSeries, quartelyDataSeries) => {
  if (configSeries?.displayName && configSeries.displayName.length > 0) {
    return configSeries.displayName;
  }
  if (annualDataSeries?.name && annualDataSeries.name.length > 0) {
    return annualDataSeries.name;
  }
  if (quartelyDataSeries?.name && quartelyDataSeries.name.length > 0) {
    return quartelyDataSeries.name;
  }

  if (configSeries?.name && configSeries.name.length > 0) {
    return configSeries.name;
  }
  if (configSeries?.seriesId && configSeries.seriesId?.annual?.length > 0) {
    return configSeries.seriesId.annual;
  }
  if (configSeries?.seriesId && configSeries.seriesId?.quarterly?.length > 0) {
    return configSeries.seriesId.quarterly;
  }
  return '';
};
