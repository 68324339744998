/* global Office */

import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import SplashScreen from '../SplashScreen/SplashScreen';

const LoginResponse = () => {
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      oktaAuth
        .getUser()
        .then(info => {
          console.log(info);
        })
        .catch(err => {
          console.log('Error Fetching user Info', err);
        });

      Office.context.ui.messageParent(JSON.stringify({ authState }));
    }
  }, [authState, oktaAuth]);

  return (
    <div id='LoginResponse'>
      <SplashScreen header='Processing login result' />
    </div>
  );
};

export default LoginResponse;
