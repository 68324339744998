import { DEV, PROD, currentEnvironment, authenticationUrls } from './urlConstants';

//env variables are always treated as strings
const forcedAuthEnv = process.env.REACT_APP_FORCE_AUTH_ENV?.toLowerCase();

const oktaIssuer = () => {
  if (currentEnvironment() === PROD || forcedAuthEnv === PROD) {
    return 'https://economist.okta-emea.com';
  }
  if (currentEnvironment() === DEV || forcedAuthEnv === DEV) {
    return 'https://economist.oktapreview.com';
  }
  return process.env.REACT_APP_OKTA_ISSUER;
};

const oktaClientId = () => {
  if (currentEnvironment() === PROD || forcedAuthEnv === PROD) {
    return '0oac6ruvdm77By3TM0i7';
  }
  if (currentEnvironment() === DEV || forcedAuthEnv === DEV) {
    return '0oa6qi40mmf9IXLR40x7';
  }
  return process.env.REACT_APP_OKTA_CLIENT_ID;
};

export const oktaAuthConfig = {
  issuer: oktaIssuer(),
  clientId: oktaClientId(),
  redirectUri: authenticationUrls.postLoginRedirectUrl,
  scopes: ['openid', 'profile', 'email', 'offline_access', 'groups']
};
