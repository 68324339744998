export const dateFormatterDDMMYYYY = date => {
  const dateObject = new Date(date);
  return (
    (dateObject.getMonth() > 8 ? dateObject.getMonth() + 1 : '0' + (dateObject.getMonth() + 1)) +
    '/' +
    (dateObject.getDate() > 9 ? dateObject.getDate() : '0' + dateObject.getDate()) +
    '/' +
    dateObject.getFullYear()
  );
};

export const convertToMonthlySheetHeaderFormat = dateString => {
  if (!dateString) return '';
  const [year, month] = dateString.split('-');
  if (!year || !month) return '';
  const date = new Date(year, month - 1);
  if (!(date instanceof Date && !isNaN(date))) return '';
  const options = { month: 'short' };
  const monthFormatted = date.toLocaleString('en-US', options);
  const yearFormatted = year.slice(-2);
  return `${monthFormatted} '${yearFormatted}`;
};

export const convertToQuarterlySheetHeaderFormat = dateString => {
  if (!dateString) return null;
  const [year, quarter] = dateString.split('-');
  if (!year || !quarter) return null;
  const shortYear = year.slice(-2);
  return `${quarter} '${shortYear}`;
};
