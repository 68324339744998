/* global Office */

import React, { useEffect } from 'react';
import { Button } from '@economist/design-system/dist/umd/common';
import { useSearchParams } from 'react-router-dom';

const sendResponse = response => {
  Office.context.ui.messageParent(response);
};

const ConfirmationDialog = () => {
  useEffect(() => {
    document.body.classList.add('body-dialog');
    return () => {
      document.body.classList.remove('body-dialog');
    };
  }, []);
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');
  let message = '',
    title = '';
  switch (type) {
    case 'review':
      message = 'You are about to send the forecast for review.  Do you want to continue?';
      title = 'Forecast ready for review';
      break;
    case 'approve':
      message = 'Once you approve the forecast, you will not be able to make any more changes.Do you want to continue?';
      title = 'Approve forecast';
      break;
    case 'reject':
      message = 'You are about to move the forecast back to draft stage.  Do you want to continue?';
      title = 'Move forecast to draft stage';
      break;
    case 'deleteSheets':
      message =
        'This action will not delete the actual forecast but will close down all the Excel sheets associated to it. Are you sure you want to proceed?';
      title = 'Delete all sheets';
      break;
    case 'resetForecast':
      message =
        'This action will remove all the changes made to the forecast and will reset it to the last published version. Are you sure you want to proceed?';
      title = 'Reset forecast';
      break;
    default:
      message = 'Are you sure you want to proceed?';
      title = 'Action confirmation';
      break;
  }
  return (
    <div className='eiu-fdp-confirmation-step'>
      <h1>{title}</h1>
      <p>{message}</p>
      <Button
        onClick={e => {
          e.preventDefault();
          sendResponse('yes');
        }}
      >
        Yes
      </Button>
      <Button
        secondary
        onClick={e => {
          e.preventDefault();
          sendResponse('no');
        }}
      >
        No
      </Button>
    </div>
  );
};

ConfirmationDialog.propTypes = {};

export default ConfirmationDialog;
