import * as React from 'react';
import { createRoot } from 'react-dom/client';
import '@economist/design-system/dist/umd/common.css';
import '@economist/design-system/dist/umd/eiu.css';
import cssVars from 'css-vars-ponyfill';
import dsVars from '../styles/dsvars';
import AppRouted from './components/App/App';

/* global Office */

cssVars({
  include: 'style,link[rel="stylesheet"]:not([href*="//"])',
  variables: dsVars
});

const title = 'EIU FDP Task Pane Add-in';

const rootElement = document.getElementById('container');
const root = createRoot(rootElement);

const renderApp = () => {
  return root.render(<AppRouted title={title} />);
};

renderApp();

/* Render application after Office initializes */
Office.onReady(() => {
  renderApp();
});

if (module.hot) {
  module.hot.accept('./components/App/App', () => {
    const NextApp = require('./components/App/App').default;
    root.render(NextApp);
  });
}
